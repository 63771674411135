import React from 'react';
import { SearchTable } from './SearchTable.js';

const SearchResultsView = ({ results, searchQuery, setVariant }) => {
  return (
    <SearchTable
      rows={results}
      searchQuery={searchQuery}
      setVariant={setVariant}
    />
  );
};

export default SearchResultsView;
