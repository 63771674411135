import { AppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useAuthCheck } from '../../components/context/authContext';
import { STATUS_AUTHENTICATED } from '../../hooks/authAPI';
import SearchBar from '../search/SearchBar';
import { WHITE } from 'styles/theme';

const CustomAppBar = styled(AppBar)({
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '72px',
  left: 0,
  background: WHITE,
  boxShadow: 'none',
  borderBottom: `1px solid #e1e2e5`,
});

const InstanceLogo = styled('img')({
  height: '3em',
  cursor: 'pointer',
});

const CustomToolbar = styled(Toolbar)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  color: '#B3B5DC',
  boxShadow: 'none', 
});

const TitleBox = styled(Box)(({ theme }) => ({
  justifySelf: 'flex-start',
  paddingLeft: theme.spacing(1.5),
}));

const ToolbarRight = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

const ToolbarItem = styled(Box)({
  marginRight: 100,
  marginTop: 5,
});

export default function TopBar({ api }) {
  const { data: session, status } = useSession();
  const authSetting = useAuthCheck();
  const router = useRouter();
  const [version, setVersion] = useState(null);

  const goHome = (event) => {
    event?.preventDefault();
    router.push('/');
  };

  useEffect(() => {
    const fetchVersion = async () => {
      if (authSetting.authEnabled && !session) {
        return;
      }

      if (version === null) {
        const versionMap = await api.fetchVersionMap();
        setVersion(versionMap.data?.version);
      }
    };

    fetchVersion();
  }, [api, authSetting, session, version]);

  const showAppBar = () => {
    let isVisible = true;
    if (authSetting.authEnabled === true && status !== STATUS_AUTHENTICATED) {
      isVisible = false;
    }
    return isVisible;
  };

  return (
    <>
      {authSetting.authEnabled === false ||
      (authSetting.authEnabled && session !== null) ? (
        <CustomAppBar
          position='absolute'
          sx={{
            visibility: showAppBar() ? 'visible' : 'hidden',
          }}
        >
          <CustomToolbar>
            <TitleBox>
              <InstanceLogo
                src='/static/base_logo.png'
                alt='Featureform'
                onClick={goHome}
                sx={{
                  height: 50,
                  marginTop: 1,
                  marginLeft: -1,
                  width: 50,
                  flexGrow: 1,
                  display: { xs: 'none', sm: 'block' },
                }}
              />
            </TitleBox>

            <ToolbarRight>
              {authSetting?.authEnabled === false || session ? (
                <ToolbarItem>
                  <SearchBar sx={{ margin: 'auto' }} homePage={false} />
                </ToolbarItem>
              ) : null}
              <Box>
                {authSetting?.authEnabled && session?.username ? (
                  <span
                    data-testid={'userNameTestId'}
                  >{`${session?.username}`}</span>
                ) : null}
                {version && (
                  <span data-testid={'versionPropId'}>{`${
                    session?.username ? ' | ' : ''
                  }Enterprise: ${version}`}</span>
                )}
              </Box>
            </ToolbarRight>
          </CustomToolbar>
        </CustomAppBar>
      ) : null}
    </>
  );
}
