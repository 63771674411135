import React from 'react';

export const UsersSvg = (props) => (
  <svg
    {...props}
    viewBox='0 0 12 12'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.4 7.80003V9.00003H0V7.80003C0 7.80003 0 5.40003 4.2 5.40003C8.4 5.40003 8.4 7.80003 8.4 7.80003ZM6.3 2.10003C6.3 1.68469 6.17684 1.27868 5.94609 0.933334C5.71534 0.587991 5.38736 0.318829 5.00364 0.159885C4.61991 0.000940597 4.19767 -0.0406464 3.79031 0.0403825C3.38295 0.121411 3.00877 0.321417 2.71508 0.615107C2.42139 0.908797 2.22138 1.28298 2.14035 1.69034C2.05932 2.0977 2.10091 2.51994 2.25985 2.90367C2.4188 3.28739 2.68796 3.61537 3.0333 3.84612C3.37865 4.07687 3.78466 4.20003 4.2 4.20003C4.75695 4.20003 5.2911 3.97878 5.68492 3.58495C6.07875 3.19113 6.3 2.65699 6.3 2.10003ZM8.364 5.40003C8.73285 5.68548 9.03466 6.04829 9.24821 6.46293C9.46175 6.87758 9.58183 7.33398 9.6 7.80003V9.00003H12V7.80003C12 7.80003 12 5.62203 8.364 5.40003ZM7.8 3.15474e-05C7.38701 -0.00226808 6.98311 0.121205 6.642 0.354032C7.00646 0.863273 7.20243 1.4738 7.20243 2.10003C7.20243 2.72626 7.00646 3.33679 6.642 3.84603C6.98311 4.07886 7.38701 4.20233 7.8 4.20003C8.35695 4.20003 8.8911 3.97878 9.28493 3.58495C9.67875 3.19113 9.9 2.65699 9.9 2.10003C9.9 1.54308 9.67875 1.00893 9.28493 0.615107C8.8911 0.221281 8.35695 3.15474e-05 7.8 3.15474e-05Z' />
  </svg>
);
