import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SessionProvider } from 'next-auth/react';
import React from 'react';
import MainContainer from '../src/components/app/MainContainer';
import AuthCheckProvider from '../src/components/context/authContext';
import ReduxStore from '../src/components/redux/store';
import ReduxWrapper from '../src/components/redux/wrapper';
import '../src/styles/base.css';
import theme from '../src/styles/theme';

export const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  return (
    <React.StrictMode>
      <SessionProvider session={session}>
        <ReduxWrapper store={ReduxStore}>
          <ThemeWrapper>
            <AuthCheckProvider>
              <MainContainer Component={Component} pageProps={pageProps} />
            </AuthCheckProvider>
          </ThemeWrapper>
        </ReduxWrapper>
      </SessionProvider>
    </React.StrictMode>
  );
};

export const views = {
  RESOURCE_LIST: 'ResourceList',
  EMPTY: 'Empty',
};

export const ThemeWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default MyApp;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
