import React, { createContext, useContext, useEffect, useState } from 'react';
import { API_URL } from '../../api/config';

const defaultSetting = Object.freeze({
  authEnabled: true,
  rbacEnabled: true,
});
export const AuthContext = createContext({ ...defaultSetting });

export function useAuthCheck() {
  if (AuthContext === undefined) {
    throw new Error('useAuthCheck is unavailable in Server Components.');
  }

  const value = useContext(AuthContext);
  if (value === undefined) {
    throw new Error(
      'useAuthCheck needs to be wrapped in <AuthCheckProvider />.'
    );
  }
  return value;
}

export default function AuthCheckProvider({ children }) {
  const [authSetting, setAuthSetting] = useState({ ...defaultSetting });

  useEffect(() => {
    const getEnabled = async () => {
      //only run on the client machine
      if (typeof window !== 'undefined') {
        const response = await fetch(`${API_URL}/data/auth/enabled`, {
          headers: { Authorization: 'Bearer empty' },
          cache: 'no-store',
        })
          .then((res) => res.json())
          .catch((error) => {
            console.error('Error fetching auth status from server: ', error);
            return { ...defaultSetting };
          });
        setAuthSetting({ ...defaultSetting, ...response });
      }
    };
    getEnabled();
  }, []);

  return (
    <AuthContext.Provider value={authSetting}>{children}</AuthContext.Provider>
  );
}
