import { getSession, signOut } from 'next-auth/react';
import { API_URL } from '../api/config';

export const STATUS_AUTHENTICATED = 'authenticated';

export async function authenticatedFetch(
  address,
  options,
  skipLogoutOnNotAuthorizedError = false
) {
  const session = await getSession();
  const accessToken = session?.accessToken;

  const fetchOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
    cache: 'no-store',
  };

  const response = await fetch(address, fetchOptions);

  if (!skipLogoutOnNotAuthorizedError && response.status === 401) {
    const responseBody = await response.json();

    // Check if the error message contains the word "expired" or "invalid"
    const errorLowered = responseBody.error.toLowerCase();
    if (errorLowered.includes('expired') || errorLowered.includes('invalid')) {
      console.log(
        `Token ${
          errorLowered.includes('expired') ? 'Expired' : 'Invalid'
        }. Signing out.`
      );
      await signOut();
    }

    // Re-throw the error
    throw new Error(responseBody.error);
  }
  return response;
}

export function useAuthAPI() {
  const currentUserIsAdmin = async () => {
    const address = `${API_URL}/data/auth/users/current/isadmin`;
    const result = await authenticatedFetch(address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addUser = async (userName) => {
    const address = `${API_URL}/data/auth/users`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName: userName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getUsers = async () => {
    const address = `${API_URL}/data/auth/users`;
    const result = await authenticatedFetch(address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getUserRoles = async (userName = '') => {
    const address = `${API_URL}/data/auth/user/getroles`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName: userName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addUserRole = async (userName = '', roleName = '') => {
    const address = `${API_URL}/data/auth/user/addrole`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName: userName, roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addUserResource = async (
    userName = '',
    resourceName = '',
    resourceVariant = '',
    resourceType = ''
  ) => {
    const address = `${API_URL}/data/auth/user/addresource`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userName: userName,
        resourceName: resourceName,
        resourceVariant: resourceVariant,
        resourceType: resourceType,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getUserResources = async (userName = '') => {
    const address = `${API_URL}/data/auth/user/getresource`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName: userName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getRoles = async () => {
    const address = `${API_URL}/data/auth/roles`;
    const result = await authenticatedFetch(address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addRole = async (roleName = '') => {
    const address = `${API_URL}/data/auth/roles`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addRoleResource = async (
    roleName = '',
    resourceName = '',
    resourceVariant = '',
    resourceType = ''
  ) => {
    const address = `${API_URL}/data/auth/role/addresource`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        roleName: roleName,
        resourceName: resourceName,
        resourceVariant: resourceVariant,
        resourceType: resourceType,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getChildRoles = async (roleName = '') => {
    const address = `${API_URL}/data/auth/role/getroles`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addChildRole = async (roleName = '', childRoleName = '') => {
    const address = `${API_URL}/data/auth/role/addrole`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        roleName: roleName,
        childRoleName: childRoleName,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getRoleResources = async (roleName = '') => {
    const address = `${API_URL}/data/auth/role/getresources`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getGroupsForRole = async (roleName = '') => {
    const address = `${API_URL}/data/auth/role/getgroups`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getRoleUsers = async (roleName = '') => {
    const address = `${API_URL}/data/auth/role/getusers`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roleName: roleName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getGroups = async () => {
    const address = `${API_URL}/data/auth/groups`;
    const result = await authenticatedFetch(address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addGroup = async (groupName = '') => {
    const address = `${API_URL}/data/auth/group`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: groupName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addGroupResource = async (
    groupName = '',
    resourceName = '',
    resourceVariant = '',
    resourceType = ''
  ) => {
    const address = `${API_URL}/data/auth/group/resource`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        groupName: groupName,
        resourceName: resourceName,
        resourceVariant: resourceVariant,
        resourceType: resourceType,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addRoleToGroup = async (roleName = '', groupName = '') => {
    const address = `${API_URL}/data/auth/group/addrole`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        roleName: roleName,
        groupName: groupName,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const addGroupToGroup = async (childGroup = '', parentGroup = '') => {
    const address = `${API_URL}/data/auth/group/addgroup`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        childGroup: childGroup,
        parentGroup: parentGroup,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getChildGroups = async (groupName = '') => {
    const address = `${API_URL}/data/auth/group/getgroups`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        groupName: groupName,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getGroupResources = async (groupName = '') => {
    const address = `${API_URL}/data/auth/group/getresources`;
    const result = await authenticatedFetch(address, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: groupName }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  const getResources = async () => {
    const address = `${API_URL}/data/auth/resources`;
    const result = await authenticatedFetch(address, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return null;
      });
    return result;
  };

  return {
    currentUserIsAdmin,
    addUser,
    getUsers,
    addUserRole,
    addUserResource,
    getUserRoles,
    getUserResources,
    getRoles,
    addRole,
    addRoleResource,
    getChildRoles,
    getRoleResources,
    getGroupsForRole,
    getRoleUsers,
    getGroups,
    addGroup,
    addGroupToGroup,
    getChildGroups,
    addGroupResource,
    addRoleToGroup,
    getGroupResources,
    getResources,
    addChildRole,
  };
}
