import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react';
import { FEATUREFORM_DARK_GRAY } from 'styles/theme';

const ListHeader = styled(Typography)(() => ({
  marginTop: 15,
  marginBottom: 0,
  display: 'flex',
  marginLeft: 15,
  color: FEATUREFORM_DARK_GRAY,
}));

export default function SideNavHeader({ displayText = 'empty' }) {
  return <ListHeader variant='body2'>{displayText}</ListHeader>;
}
