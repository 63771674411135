import React from 'react';

//todo: fix this opr discard prometheus graphs
// todo: old props {query, type, name, query_type, add_labels, remote}
function QueryDropdown() {
  // const classes = useStyles();

  return (
    <></>
    // <div className={classes.root}>
    //   <PrometheusGraph
    //     query={query}
    //     type={type}
    //     name={name}
    //     query_type={query_type}
    //     add_labels={add_labels}
    //     remote={remote}
    //   />
    // </div>
  );
}

export default QueryDropdown;
