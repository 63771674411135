import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/system';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Resource from '../../api/resources/Resource';
import { STATUS_AUTHENTICATED, useAuthAPI } from '../../hooks/authAPI';
import { useAuthCheck } from '../context/authContext';
import SideNavHeader from './SideNavHeader';
import SideNavItem from './SideNavItem';
import { WHITE } from 'styles/theme';

const drawerWidth = 225;
const drawerTop = 70;

const PREFIX = 'SideNav';

const classes = {
  drawer: `${PREFIX}-drawer`,
  navBox: `${PREFIX}-navBox`,
  mainContent: `${PREFIX}-mainContent`,
  listItemHome: `${PREFIX}-listItemHome`,
  listItem: `${PREFIX}-listItem`,
  listIcon: `${PREFIX}-listIcon`,
  listIconImg: `${PREFIX}-listIconImg`,
  listHeader: `${PREFIX}-listHeader`,
  listSvg: `${PREFIX}-listSvg`,
};

const Root = styled('div')(() => ({
  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      background: WHITE,
      width: drawerWidth,
      boxSizing: 'border-box',
      top: `${drawerTop}px`,
      borderTop: '1px solid #e1e2e5',
    },
  },
  [`& .${classes.navBox}`]: {
    display: 'flex',
  },
  [`& .${classes.mainContent}`]: {
    flexGrow: 1,
    p: 3,
  },
  [`& .${classes.listItemHome}`]: {
    padding: '0 0 0 0',
    color: '#B3B5DC',
    backgroundColor: '#111014',
  },
  [`& .${classes.listItem}`]: {
    padding: '0 0 0 0',
    marginBottom: -5,
    color: '#B3B5DC',
  },
  [`& .${classes.listIcon}`]: {
    minWidth: 32,
  },
  [`& .${classes.listIconImg}`]: {
    marginBottom: 5,
  },
  [`& .${classes.listHeader}`]: {
    marginTop: 15,
    marginBottom: 0,
    display: 'flex',
    marginLeft: 15,
    color: '#4F5174',
  },
  [`& .${classes.listSvg}`]: {
    width: '50%',
  },
}));

export { classes };

function SideNav({ sections }) {
  const authApi = useAuthAPI();
  const authSetting = useAuthCheck();
  const { data: session, status } = useSession();
  const router = useRouter();

  const [isAdmin, setIsAdmin] = useState(null);
  const [username, setUserName] = useState('');

  useEffect(() => {
    const setSession = async () => {
      if (
        authSetting.authEnabled &&
        session &&
        status === STATUS_AUTHENTICATED &&
        !username
      ) {
        setUserName(session.username);
        await authApi.addUser(session.username);
      }
    };
    setSession();
  }, [authSetting.authEnabled, status, session]);

  useEffect(() => {
    const checkAdmin = async () => {
      if (
        authSetting.authEnabled &&
        session &&
        status === STATUS_AUTHENTICATED &&
        isAdmin === null
      ) {
        const isAdmin = await authApi.currentUserIsAdmin();
        setIsAdmin(isAdmin);
      }
    };
    checkAdmin();
  }, [authSetting.authEnabled, status, session]);

  function handleMenuClick(event, urlPath) {
    event.preventDefault();
    router.push(urlPath);
  }

  const adminSections = [
    {
      title: 'Users',
      urlPath: '/admin/users',
      icon: 'person',
    },
    { title: 'Roles', urlPath: '/admin/roles', icon: 'engineer' },
    { title: 'Groups', urlPath: '/admin/groups', icon: 'group' },
  ];

  return (
    <>
      {authSetting?.authEnabled === false ||
      (authSetting.authEnabled && session) ? (
        <Root>
          <Box className={classes.navBox}>
            <CssBaseline />
            <Drawer variant='permanent' className={classes.drawer}>
              <List>
                <SideNavItem
                  handler={handleMenuClick}
                  iconType={'home'}
                  urlPath={'/'}
                  currentPath={router?.asPath}
                  displayText={'Home'}
                />
                <SideNavHeader displayText={'Resources'} />
                {sections?.['features'].map((currentSection, index) => {
                  let resourceType = Resource[currentSection.type];
                  return (
                    <SideNavItem
                      key={index + 100}
                      handler={handleMenuClick}
                      iconType={resourceType.materialIcon}
                      urlPath={resourceType.urlPath}
                      currentPath={router?.asPath}
                      displayText={resourceType.typePlural}
                    />
                  );
                })}
                <SideNavHeader displayText={'Scheduling'} />
                <SideNavItem
                  handler={handleMenuClick}
                  iconType='tasks'
                  urlPath={'/tasks'}
                  currentPath={router?.asPath}
                  displayText={'Tasks'}
                />
                {isAdmin && authSetting.rbacEnabled !== false ? (
                  <>
                    <SideNavHeader displayText={'User Management'} />
                    {adminSections.map((currentSection, index) => {
                      return (
                        <SideNavItem
                          key={index + 200}
                          handler={handleMenuClick}
                          iconType={currentSection.icon}
                          urlPath={currentSection.urlPath}
                          currentPath={router?.asPath}
                          displayText={currentSection.title}
                        />
                      );
                    })}
                  </>
                ) : null}
                {authSetting.authEnabled && session ? (
                  <>
                    <SideNavItem
                      handler={signOut}
                      iconType={'exit'}
                      displayText={'Log out'}
                    />
                  </>
                ) : null}
              </List>
            </Drawer>
          </Box>
        </Root>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  sections: state.homePageSections,
});

export default connect(mapStateToProps)(SideNav);
