import { Box, Button, Link } from '@mui/material';
import { styled } from '@mui/system';
import { signIn, useSession } from 'next-auth/react';
import React from 'react';
import { useAuthCheck } from '../context/authContext';

const MainContainer = styled(Box)({
  height: '100vh',
  backgroundImage: 'linear-gradient(180deg, #0A0909 23%, #7A15E5)',
});

const LoginContainer = styled(Box)({
  position: 'absolute',
  top: 150,
  width: '100%',
  textAlign: 'center',
});

const CreaturePng = styled('img')({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 10,
  height: 125,
});

const LoginBox = styled(Box)({
  background: 'rgba(10,9,9,.3)',
  borderTop: '2px solid #F7195C',
  borderBottom: '2px solid #7A14E5',
  margin: 'auto',
  height: 275,
  width: 600,
  textAlign: 'center',
});

const SignInButton = styled(Button)({
  height: 50,
  width: 200,
});

const Links = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 7,
});

export default function SignOn({ children }) {
  const { data: session } = useSession();
  const authSetting = useAuthCheck();
  const creaturePng = '/static/creature.png';

  const redirect = (url = '', newTab = true) => {
    if (url) {
      if (newTab) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      } else {
        window.open('mailto:' + url);
      }
    }
  };

  return (
    <>
      {authSetting.authEnabled && session === null ? (
        <MainContainer>
          <LoginContainer>
            <CreaturePng alt={'Featureform Mascot'} src={creaturePng} />
            <LoginBox>
              <Box sx={{ opacity: 'unset' }}>
                <h1 style={{ color: '#F7195C' }}>Welcome to Featureform</h1>
                <SignInButton
                  onClick={() => signIn('custom-okta')}
                  variant='contained'
                >
                  <strong>LOGIN WITH OKTA</strong>
                </SignInButton>
              </Box>
            </LoginBox>
            <Links>
              <Link
                component={'button'}
                variant='body2'
                underline='none'
                sx={{ color: '#7A14E5', marginRight: 5 }}
                onClick={() =>
                  redirect('https://www.featureform.com/privacy-policy')
                }
              >
                Privacy
              </Link>
              <Link
                component={'button'}
                variant='body2'
                underline='none'
                sx={{ color: '#7A14E5' }}
                onClick={() => redirect('legal@featureform.com', false)}
              >
                Terms
              </Link>
            </Links>
          </LoginContainer>
        </MainContainer>
      ) : authSetting.authEnabled === false || session ? (
        <Box>{children}</Box>
      ) : (
        <></>
      )}
    </>
  );
}
